body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f7f7;
  /*overflow-x: hidden;*/
  /*background-color: #e9e9e9;*/
  /*background-color: #3f3f47;*/
}
* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: inherit;
}

.Hints li {
  line-height: 1.5em;
  margin-bottom: 0.2em;
}

.Hints a {
  text-decoration: none;
  color: dodgerblue;
}

.Advice {
  margin: 1em;
  overflow: auto;
  width: 80%;
  max-width: 1000px;
}
@media screen and (max-width: 1280px) {
  .Advice {
    width: 80%;
    margin: 0;
  }
}
@media screen and (max-width: 1000px) {
  .Advice {
    width: 100%;
    margin: 0;
  }
}
.Advice li {
  line-height: 1.5em;
  margin-bottom: 0.2em;
}

.Advice ol {
  padding-inline-start: 2em;
}

.Advice a {
  text-decoration: none;
  color: dodgerblue;
}

.Advice code {
  background: #222;
  border-radius: 4px;
  padding: 2px 4px;
  color: rgb(241, 234, 199);
}
.Advice pre {
  background: #222;
  border-radius: 4px;
  padding: 5px 8px;
  color: rgb(241, 234, 199);
  overflow: auto;
}

.Advice pre code {
  padding: 0;
}

.icon {
  width: 2em;
  height: 2em;
  vertical-align: middle;
  fill: currentColor;
  overflow: hidden;
  margin-right: 4px;
}

.Advice blockquote {
  border-left: 4px solid rgb(166, 133, 64);
  margin-left: 20px;
  margin-right: 20px;
  padding-left: 10px;
  background-color: rgb(241, 234, 199);
  border-radius: 0 0.5em 0.5em 0;
}

.Advice blockquote p {
  padding: 0.5em 0;
}

.Advice table {
  width: 100%;
  border-collapse: collapse;
}

.Advice table tr {
  line-height: 2;
  background: #fff;
}

.Advice table thead tr {
  background: #ccc;
}

.Advice td {
  border: 2px solid #bbb;
  padding: 0.5em;
}

.LinkCard:hover {
  transition: all 0.2s ease-in-out;
  transform: scale(1);
}
